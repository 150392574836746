<script>
import Naves from '@/views/com/Naves'
import { Card, Radio, Tabs } from 'ant-design-vue'
import { getAction } from '@/command/netTool'
import TableViewNew from '@/components/TableViewNew'
import moment from 'moment'
import { Tooltip } from 'ant-design-vue'
export default {
  data() {
    return {
      active: 0,
      selectType: '0',
      banInfo: {},
      tabsList: { records: [] },
      tabsListData: [[], []],
      pageParams: {
        dateType: '0',
      },
    }
  },
  mounted() {
    // 获取收益
    this.getKanBanInfo()
  },
  watch: {
    selectType: function (newValue) {
      this.getKanBanInfo()
      switch (newValue) {
        case '0':
          this.pageParams = {
            dateType: '0',
          }
          break
        case '1':
          this.pageParams = {
            dateType: '1',
          }
          break
        case '2':
          this.pageParams = {
            dateType: '2',
          }
          break
        default:
          this.pageParams = {
            dateType: '',
          }
          break
      }
    },
  },
  methods: {
    getKanBanInfo() {
      getAction('/kanBan/getKanBanInfo', { dateType: this.selectType }).then((e) => {
        this.banInfo = e.data
      })
    },
    renderPanel(data) {
      return (
        <div class="home-top">
          {data.map((e) => {
            return (
              <div class="home-top-item" onClick={() => e.onClick()}>
                <div>
                  {e.value || 0}
                  {e.right && e.right()}
                </div>
                <div>{e.name}</div>
              </div>
            )
          })}
        </div>
      )
    },
    getTime() {
      switch (this.selectType) {
        case '0':
          return moment().format('YYYY-MM-DD')
        case '1':
          return `${moment().startOf('week').format('YYYY-MM-DD')}~${moment().endOf('week').format('YYYY-MM-DD')}`
        case '2':
          return `${moment().startOf('month').format('YYYY-MM-DD')}~${moment().endOf('month').format('YYYY-MM-DD')}`
        default:
          return '全部'
      }
    },
    renderTop() {
      const left = [
        {
          name: '酒店待确认订单',
          value: this.banInfo.toBeConfirmedOrderNum,
          onClick: () => this.$router.push('/orderProcessing/hotel'),
        },
        {
          name: '待发货订单',
          value: this.banInfo.deliveredOrderNum,
          onClick: () => this.$router.push('/orderProcessing/localProducts'),
        },
        {
          name: '待退款订单',
          value: this.banInfo.refundingOrderNum,
          onClick: () => this.$router.push('/orderProcessing/refund'),
        },
      ]
      const right = [
        {
          name: '景区数量',
          value: this.banInfo.scenicNum,
          onClick: () => this.$router.push('/commodityManagement/scenicAreas'),
        },
        {
          name: '酒店数量',
          value: this.banInfo.hotelNum,
          onClick: () => this.$router.push('/commodityManagement/hotelHomestayManage'),
        },
        {
          name: '订单数量',
          value: this.banInfo.orderCount,
        },
        {
          name: '结算价',
          value: this.banInfo.salesJsAmount,
        },
        {
          name: '利润',
          value: this.banInfo.profitAmount,
        },
        {
          name: '平台累积交易金额',
          value: this.banInfo.totalAmount,
        },
      ]
      return (
        <div class="top-panel">
          {this.renderPanel(left)}
          {this.renderPanel(right)}
        </div>
      )
    },
    getColumns(index) {
      if (index == 0) {
        return [
          {
            dataIndex: 'businessName',
            title: '景点名称',
          },
          {
            dataIndex: 'orderCount',
            title: '总订单量',
            filter: true,
            align: 'center',
            sorter: (a, b) => a.orderCount - b.orderCount,
          },
          {
            title: '总订单金额',
            dataIndex: 'totalAmount',
            sorter: (a, b) => a.totalAmount - b.totalAmount,
            // customRender: (text) => {
            //   if (!text) return '0.0'
            //   return <span>{parseFloat(text).toFixed(2)}</span>
            // },
          },
          {
            title: '核销数量',
            dataIndex: 'productQuantityCompleted',
            sorter: (a, b) => a.productQuantityCompleted - b.productQuantityCompleted,
          },
          {
            title: '核销金额',
            dataIndex: 'totalAmountCompleted',
            sorter: (a, b) => a.totalAmountCompleted - b.totalAmountCompleted,
          },
          {
            title: '退款数量',
            dataIndex: 'refundCount',
            sorter: (a, b) => a.refundCount - b.refundCount,
          },
          {
            title: '退款金额',
            dataIndex: 'refundAmount',
            sorter: (a, b) => a.refundAmount - b.refundAmount,
          },
          {
            title: '手续费',
            dataIndex: 'feeAmount',
            sorter: (a, b) => a.feeAmount - b.feeAmount,
          },
          {
            title: '结算金额',
            dataIndex: 'salesJsAmount',
            sorter: (a, b) => a.salesJsAmount - b.salesJsAmount,
          },
          {
            dataIndex: 'a1',
            title: '操作',
            type: 'buttonGroup',
            align: 'center',
            typeData: ({ records }) => {
              return [
                {
                  name: '详情',
                  onClick: () => {
                     this.$router.push(
                      `/dataReport/businessDetail?businessId=${records.businessId}&fromType=${records.fromType}&businessName=${records.businessName}`
                    )
                  },
                },
              ]
            },
          },
        ]
      } else {
        return [
          {
            dataIndex: 'businessName',
            title: '酒店名称',
          },
          {
            title: '总订单量',
            dataIndex: 'orderCount',
            sorter: (a, b) => a.orderCount - b.orderCount,
          },
          {
            title: '总订单金额',
            dataIndex: 'totalAmount',
            sorter: (a, b) => a.totalAmount - b.totalAmount,
          },
           {
            title: '核销数量',
            dataIndex: 'productQuantityCompleted',
            sorter: (a, b) => a.productQuantityCompleted - b.productQuantityCompleted,
          },
          {
            title: '核销金额',
            dataIndex: 'totalAmountCompleted',
            sorter: (a, b) => a.totalAmountCompleted - b.totalAmountCompleted,
          },
          {
            title: '退款数量',
            dataIndex: 'refundCount',
            sorter: (a, b) => a.refundCount - b.refundCount,
          },
          {
            title: '退款金额',
            dataIndex: 'refundAmount',
            sorter: (a, b) => a.refundAmount - b.refundAmount,
          },
          {
            title: '手续费',
            dataIndex: 'feeAmount',
            sorter: (a, b) => a.feeAmount - b.feeAmount,
          },
          {
            title: '结算金额',
            dataIndex: 'salesJsAmount',
            sorter: (a, b) => a.salesJsAmount - b.salesJsAmount,
          },
          
          {
            dataIndex: 'a1',
            title: '操作',
            type: 'buttonGroup',
            align: 'center',
            typeData: ({ records }) => {
              return [
                {
                  name: '详情',
                  onClick: () => {
                    this.$router.push(
                      `/dataReport/businessDetail?businessId=${records.businessId}&fromType=${records.fromType}&businessName=${records.businessName}`
                    )
                  },
                },
              ]
            },
          },
        ]
      }
    },
    renderFoot() {
      const data = [
        {
          name: '合计总订单量',
          value: 100,
        },
        {
          name: '合计订单数',
          value: 100,
        },
      ]
      return (
        <div>
          {data.map((e) => {
            return (
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{e.name}</span>
                <span>{e.value}</span>
              </div>
            )
          })}
        </div>
      )
    },

    renderBody() {
      const data = [
        {
          name: '景点门票',
          value: 0,
          url: '/reportForm/getMerchantReportPage',
        },
        {
          name: '酒店民宿',
          value: 1,
          url: '/reportForm/getMerchantReportPage',
        },
      ]
      return (
        <Card style={{ marginTop: '10px' }}>
          <Naves
            navData={['景点门票', '酒店民宿']}
            onActive={(index) => {
              this.active = index
            }}
            activeCurrentKey={this.active}
          />
          <Tabs v-model={this.active} animated={false}>
            {data.map((e, i) => {
              return (
                <Tabs.TabPane tab={e.name} key={i}>
                  <TableViewNew url={e.url} columns={this.getColumns(i)} pageParams={{...this.pageParams,fromType: i == 0 ? '1201': '13'}} />
                </Tabs.TabPane>
              )
            })}
          </Tabs>
        </Card>
      )
    },
    renderType() {
      const data = [
        {
          name: '日',
          value: '0',
        },
        {
          name: '周',
          value: '1',
        },
        {
          name: '月',
          value: '2',
        },
        {
          name: '总',
          value: '',
        },
      ]
      return (
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
          <Radio.Group v-model={this.selectType}>
            {data.map((e) => {
              return <Radio.Button value={e.value}>{e.name}</Radio.Button>
            })}
          </Radio.Group>
        </div>
      )
    },
  },
  render() {
    return (
      <div class="scenicSpot">
        {this.renderType()}
        {this.renderTop()}
        {this.renderBody()}
      </div>
    )
  },
}
</script>
<style lang="less">
.scenicSpot {
  .ant-tabs-nav-wrap {
    display: none;
  }
}
</style>
<style lang="less" scoped>
.top-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.home-top {
  display: flex;
  align-items: center;
  height: 101px;
  width: 49%;
  background: white;
  .home-top-item {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'PingFang SC Normal', 'PingFang SC';
    font-weight: 500;
    font-style: normal;
    font-size: 20px;
    color: #515a6e;
    &:not(:last-child)::after {
      position: absolute;
      right: 0px;
      top: 0px;
      bottom: 0px;
      width: 1px;
      background: rgb(229, 231, 233);
      content: '';
    }
    & > div:nth-child(2){
      font-size: 16px;
    }
  }
}
</style>
